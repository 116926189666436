<template>
  <tabs-modal
    ref="tabsModal"
    class="focus--on"
    :show="true"
    @close="close"
    :isFocusModeEnabled="true"
  >
    <div
      class="loader"
      style="z-index: 51;"
      slot="focus"
      v-if="submitting || !tweets || !schedule || !areUsersBestTweetsFetched"
    >
      <span class="loading loading-lg"><inline-svg src="/img/icons/loading.svg"></inline-svg></span>
    </div>

    <div v-if="tweets && schedule" slot="close-icon" class="z-50 p-4 block">
      <div>
        <div class="flex items-center justify-end">
          <tooltip content="Exit Focus Mode">
            <router-link to="/">
              <base-button class="btn icon text-muted outline border-divider">
                <!-- remove.svg -->
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"
                  ></path>
                </svg>
              </base-button>
            </router-link>
          </tooltip>
        </div>
      </div>
    </div>
    <div v-if="tweets && schedule && areUsersBestTweetsFetched" slot="focus" class="flex flex-col h-full scheduling-game">

      <h1 v-if="$route.query.fromSetup">
        <div v-if="3 - numberOfScheduledPosts > 0">Let's build some momentum, only <span class="counter">{{ 3 - numberOfScheduledPosts }}</span> {{ 3 - numberOfScheduledPosts > 1  ? 'tweets' : 'tweet'}} left!</div>
        <div v-else class="text-green-500">Done! ✔</div>
      </h1>
      <h1 v-else>
        <span v-if="slotsLeftToFillWeek - numberOfScheduledPosts > 0">
          <span class="counter">{{ slotsLeftToFillWeek - numberOfScheduledPosts }}</span> {{ slotsLeftToFillWeek > 1  ? 'tweets' : 'tweet'}} left to fill the week
        </span >
        <span v-else class="text-green-500">You have filled this week's queue! ✔</span>
      </h1>

      <div class="flex items-center justify-between">
        <div class="font-weight-medium text-muted">Choose some categories to get inspired...</div>
        <button class="bg-transparent text-sm text-accent border-0 outline-none focus:bg-transparent focus:outline-none hover:bg-transparent hover:text-secondary" @click.prevent="toggleCategories">
          <span v-if="showInspirationCategories">Hide</span>
          <span v-else>Show</span>
        </button>
      </div>
      <div v-if="showInspirationCategories" class="inspiration-categories grid grid-cols-2 gap-x-2 sm:grid-cols-3 md:flex md:flex-wrap mt-3">
        <div
          class="inspiration-categories-tags custom-tags max-w-full overflow-hidden"
          v-for="category in categories"
          :key="category.id"
        >
          <label>
            <span
              :title="category.name"
              :class="categoriesState[category.name]"
              @click="toggleState(category)"
            >
              {{ category.name }}
            </span>
          </label>
        </div>
      </div>

      <hr class="my-6"/>

      <div ref="tweet_container" class="textarea-container">
        <textarea
          ref="tweet"
          v-model="focusModeStatus"
          placeholder="Start typing..."
          class="textarea form-control max-w-max p-4"
          @keydown.ctrl.enter="addToQueue"
          @keydown.meta.enter="addToQueue"
          @keydown.ctrl.73="skipInspirationTweet"
          @keydown.meta.73="skipInspirationTweet"
        ></textarea>
      </div>

      <div class="space-y-3 mt-6">

        <div v-if="isUserConnectedToLinkedIn" class="flex items-center justify-between">
          <div class="font-weight-medium">Crosspost to LinkedIn</div>
          <base-switch v-model="shouldCrosspostToLinkedIn"></base-switch>
        </div>

        <div v-if="shouldCrosspostToLinkedIn" class="flex items-center justify-between">
          <div class="font-weight-medium">Post publicly to LinkedIn</div>
          <base-switch v-model="shouldPostPubliclyToLinkedIn"></base-switch>
        </div>

        <div v-if="isUserConnectedToFacebook" class="flex items-center justify-between">
          <div class="font-weight-medium">Crosspost to Facebook</div>
          <base-switch v-model="shouldCrosspostToFacebook"></base-switch>
        </div>

      </div>

      <div class="flex items-center justify-end mt-6">

        <div
          class="sm:flex-row flex-col flex sm:space-x-2 space-x-0 space-y-2 sm:space-y-0 sm:justify-end justify-center w-full"
        >
          <base-button
            data-cy="add-to-queue-button"
            type="primary"
            class="btn text secondary"
            v-if="tweets.length > 0"
            @click="skipInspirationTweet"
          >
            <span class="flex items-center space-x-4">
              <span>Skip</span>
              <span class="hidden sm:block flex items-center space-x-1 font-mono text-xs">
                <span class="keyboard-shortcut">{{commandOrControl}}</span><span>+</span><span class="keyboard-shortcut">I</span>
              </span>
            </span>
          </base-button>

          <base-button
            data-cy="add-to-queue-button"
            type="primary"
            class="btn text primary"
            :disabled="!canFormBeSubmitted()"
            @click="addToQueue"
          >
            <span class="flex items-center space-x-4">
              <span v-if="$route.query.fromSetup">Schedule</span>
              <span v-else>Add to Queue</span>
              <span class="hidden sm:block flex items-center space-x-1 font-mono text-xs">
                <span class="keyboard-shortcut">{{commandOrControl}}</span><span>+</span><span class="keyboard-shortcut">Enter</span>
              </span>
            </span>
          </base-button>
        </div>
      </div>
    </div>
  </tabs-modal>
</template>

<script>
  import { adjustTextAreaHeight } from '../util/adjustTextAreaHeight';
  import { commandOrControl } from '../util/commandOrControl';
  import TabsModal from '@/components/TabsModal';
  import ThreadMixin from '@/views/Mixins/ThreadMixin';
  import MobileMixin from '@/views/Mixins/MobileMixin.vue';
  import { countTweetLength } from '@/../functions/src/util/countTweetLength';
  import { mapGetters, mapState } from 'vuex';
  import { Thread } from '@/models/Thread';
  import lodash from 'lodash';
  import { EmptySlot } from '../models/EmptySlot';
  import InspirationCategoriesMixin from '@/views/Mixins/InspirationCategoriesMixin.vue';
  import LinkedInMixinVue from './Mixins/LinkedInMixin.vue';
  import FacebookMixinVue from './Mixins/FacebookMixin.vue';
  import { store } from '@/store';

  export default {
    components: {
      TabsModal,
    },
    computed: {
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
      ...mapState(['schedule', 'isWholeScheduleLoaded']),
      commandOrControl,
    },
    data() {
      return {
        currentInspirationTweet: null,
        slotsLeftToFillWeek: 0,
        numberOfScheduledPosts: 0,
        shouldCrosspostToLinkedIn: false,
        shouldCrosspostToFacebook: false,
        shouldPostPubliclyToLinkedIn: false,
        previousTime: null,
        ...this.initialState(),
      };
    },
    mounted() {
      this.fetchScheduleIfItsNull();
    },
    methods: {
      getNumberOfSlotsLeftToFillWeek() {
        const userSchedule = Object.values(this.schedule.getThreadsByDate());
        const oneWeekOfThreads = lodash.flatten(lodash.take(userSchedule, 7));

        const numberOfEmptyTweetsForNextWeek = oneWeekOfThreads.filter(
          (thread) => thread instanceof EmptySlot
        ).length;
        this.slotsLeftToFillWeek = numberOfEmptyTweetsForNextWeek;
      },
      skipInspirationTweet() {
        this.tweets = this.tweets.filter(
          (tweet) => tweet.id !== this.currentInspirationTweet.id
        );

        if (this.tweets.length === 0) {
          this.currentInspirationTweet = null;
          Object.assign(this.$data, this.initialState());
          return;
        }

        if (this.$refs.tweet) {
          this.$refs.tweet.focus();
        }
        this.updateStatusWithRandomInspirationTweet();
      },
      updateStatusWithRandomInspirationTweet() {
        const inspirationTweetIndex = Math.floor(Math.random() * this.tweets.length);
        this.currentInspirationTweet = (() => {
          const tweet = this.tweets[inspirationTweetIndex];
          return !lodash.isNil(tweet) &&
            !lodash.isNil(tweet.user) &&
            tweet.user.username !== this.userProfile.username
            ? { ...tweet, text: tweet.text + `\n\ninspired by @${tweet.user.username}` }
            : tweet;
        })();
        this.focusModeStatus = this.currentInspirationTweet
           ? this.currentInspirationTweet.text
           : '';
      },
      initialState() {
        return {
          submitting: false,
          focusModeStatus: '',
          time: null,
          previousHeight: 79,
        };
      },
      async addToQueue() {
        const time = this.previousTime
          ? this.schedule.getNextTimeSlot(this.previousTime)
          : this.schedule.getNextTimeSlot();
        
        const tweets = [{
          count: 0,
          media: null,
          published: false,
          status: this.focusModeStatus.trim(),
        }];

        const linkedInInfo = this.shouldCrosspostToLinkedIn
          ? {
              isPostPublic: this.shouldPostPubliclyToLinkedIn,
              text: this.filterOutEmptyTweets(tweets)[0].status,
            }
          : null;
        const facebookInfo = this.shouldCrosspostToFacebook
          ? { text: this.filterOutEmptyTweets(tweets)[0].status }
          : null;

        const thread = Thread.newThread({
          time,
          tweets: this.filterOutEmptyTweets(tweets),
          user: this.userProfile.uid,
          linkedIn: linkedInInfo,
          facebook: facebookInfo,
        });

        if (!this.canFormBeSubmitted()) return;

        this.previousTime = time;

        try {
          this.submitting = true;

          await thread.saveToFirestore(this.currentUser, this.userProfile.uid, this.userProfile.timezone, this.$eventStore);

          this.numberOfScheduledPosts++;
          Object.assign(this.$data, this.initialState());
          this.skipInspirationTweet();

          this.$notify({ type: 'success', message: 'Tweet scheduled successfully!'});

          this.$refs.tweet.focus();
        } catch (error) {
          this.submitting = false;
          this.$notify({ type: 'warning', message: 'An error has occurred while trying to schedule the tweet!'});
        }

      },
      canFormBeSubmitted() {
        const tweetLength = countTweetLength(this.focusModeStatus.trim());
        const isTweetLengthValid = tweetLength > 0 && tweetLength <= 280;
        return isTweetLengthValid && !this.submitting;
      },
      close() {
        this.$eventStore.closeComposerWithoutScheduling();
        this.showTimeBox = false;
      },
      fetchScheduleIfItsNull() {
        if (this.schedule) {
          this.getNumberOfSlotsLeftToFillWeek();
        } else {
          store.dispatch('fetchSchedule');
        }
      },
    },
    watch: {
      focusModeStatus: {
        immediate: true,
        handler: function () {
          this.$nextTick(function () {
            if (this.$refs.tweet) {
              this.previousHeight = adjustTextAreaHeight(this.$refs.tweet, this.$refs.tweet_container, this.previousHeight);
            }
          });
        }
      },
      tweets(val, old) {
        this.updateStatusWithRandomInspirationTweet();
        if (old.length === 0 && val.length !== 0) {
          this.$nextTick(() => {
            if (this.$refs.tweet) {
              this.$refs.tweet.focus();
            }
          });
        }
      },
      schedule(val, old) {
        if (old === null && val) {
          this.getNumberOfSlotsLeftToFillWeek();
        }
      },
      numberOfScheduledPosts(val) {
        if (this.$route.query.fromSetup && 3 - val <= 0) {
          this.$router.push('/queue');
        }
      },
    },
    mixins: [ThreadMixin, InspirationCategoriesMixin, MobileMixin, LinkedInMixinVue, FacebookMixinVue],
  };
</script>

<style lang="scss" scoped>
  .textarea {
    font-size: 1.4rem;
    position: relative;
    letter-spacing: -0.2px;
    width: 100%;
    min-height: 250px;
    min-width: 250px;
    flex: 1 1 0%;
    overflow-y: scroll;
    caret-color: var(--color-accent);
    transition: padding 0.85s cubic-bezier(0.4, 0, 0.05, 1.2) 0s;
    outline: none;
    background: transparent;
    line-height: 1.35;
    font-weight: 400;
    box-sizing: border-box;
    background-color: var(--color-dark);

    @media (max-width: 600px) {
      min-height: 300px;
    }
  }

  .scheduling-game {
    @media (max-width: 767px) {
      margin-top: 150px;
    }

    @media (max-height: 900px) {
      margin-top: 40px;
    }

    @media (max-height: 800px) {
      margin-top: 0px;
    }
  }

  .textarea-container {

    textarea {
      padding: 1rem!important;
    }

  }

  .h-80vh {
    height: 80vh;
  }
  .font-mono {
    font-family: monospace;
  }
  .keyboard-shortcut {
    @apply bg-divider-darker py-1 px-2 rounded text-xs text-white;
  }
  .inspiration-categories {

    @screen md {
      max-width: fit-content;
    }

  }
  .inspiration-categories-tags {
    @apply mr-0;

    label {
      span {
        @apply select-none capitalize;

        &.hypefury {
          @apply bg-accent text-tag;
        }

        &.both {
          @apply bg-purple-500 text-tag;
        }

        &.user {
          @apply bg-red-500 text-tag;
        }
      }
    }
  }
  .counter {
    @apply bg-green-500 text-dark inline-block w-10 h-10 inline-flex leading-none items-center justify-center rounded-full;
  }
</style>
